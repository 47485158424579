import { Configuration } from '@/stores/admin/configuration'
import { Order, PaginatedCollection } from '@/types'

import ApiService from './api_service'

export default {
  async index(): Promise<Order[]> {
    const response = await ApiService.get(`/shopify-app/${Configuration().shopifyShopId}/orders`)
    return response.data
  },

  async cancelByCustomer(id: number | string): Promise<Order> {
    const response = await ApiService.post(`/shopify-app/${Configuration().shopifyShopId}/orders/${id}/cancel`)
    return response.data
  },

  async get(id: number | string): Promise<Order> {
    const response = await ApiService.get(`/shopify-app/${Configuration().shopifyShopId}/orders/${id}`)
    return response.data
  },

  async checkout(orderId: number | string, orderKey: number | string): Promise<any> {
    const response = await ApiService.get(`/orders/${orderId}/${orderKey}/checkout`)
    return response.data
  },

  async cancelCheckout(orderId: number | string, orderKey: number | string): Promise<Order> {
    const response = await ApiService.post(`/orders/${orderId}/${orderKey}/cancel`)
    return response.data
  },

  async completeCheckout(orderId: number | string, orderKey: number | string): Promise<Order> {
    const response = await ApiService.get(`/orders/${orderId}/${orderKey}/complete`)
    return response.data
  }
}
